import React from "react";
import { Link } from "gatsby";
import * as s from "./trialButton.module.css";

const TrialButton = ({ layout, scrollToForm, classes, children }) => {
  // console.log(typeof scrollToForm, scrollToForm);
  // console.log(typeof children, children);
  if (!layout) {
    layout = 1;
  }
  let button = "";
  switch (typeof scrollToForm) {
    case "function":
      button = (
        <button
          onClick={scrollToForm}
          className={`${classes.join(" ")} ${s.trialButton} ${s[`layout${layout}`]}`}
        >
          {children}
        </button>
      );
      break;
    case "string":
    default:
      button = (
        <Link
          to={"/contact-us/"}
          className={`${classes.join(" ")} ${s.trialButton} ${s[`layout${layout}`]}`}
        >
          {children}
        </Link>
      );
      break;
  }
  return <>{button}</>;
};

export default TrialButton;
